ion-tab-button.tab-selected {
    --background: white !important;
    --color-selected: var(--ion-color-primary);
  }
ion-tab-button{
    --background:var(--ion-color-secondary) !important;
    --color: white;
}
.center-text{
  text-align:center;
}

.card-disabled{
  color:#DADADA !important;
  border: 1px solid #DADADA !important;
}

.state__current{
  color: var(--ion-color-success);

}
.route__type{
  color:var(--ion-color-primary);
  text-transform: capitalize;
}
.margin__right{
  margin-right: 5px;
}
.error__label{
  font-weight: 400;
font-size: 12px;
line-height: 14px;
margin-top: 6px;
color: var(--ion-color-primary);


}
.button__action{
  
  --border-radius: 48px;
  height: 42px;
  
 
}

.button__form{
  align-self: center;
}
.card-disabled{
  color: #9E9E9E!important;
  border: 1px solid #9E9E9E !important;
  background-color: white !important;
}
.form__align{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.confirm{
  font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align:center;
}
.required{
  color: var(--ion-color-primary);
}

ion-modal#example-modal {
    
  --width: 300px;
  --height: fit-content;
  --border-radius: 24px;
 
  padding-inline: 16px;
}
#ion-react-wrapper{
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;
  background: white;
}

.password__link{
  font-size: 16px;
line-height: 24px;

/* or 150% */
text-align: center;
letter-spacing: 0.5px;
text-decoration-line: underline;

/* Secondary/Main */
color: #FF4214;
}

